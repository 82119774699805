import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { LoadingSpinnerCircleComponent } from './components/loading-spinner-circle/loading-spinner-circle.component';
import { BloodSupplyAnimatedComponent } from './components/blood-supply-animated/blood-supply-animated.component';
import { registerLocaleData } from '@angular/common';
import me from '../locale/me';
import { CalendarNativeDateFormatter, DateFormatterParams, CalendarModule, DateAdapter, CalendarDateFormatter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PaginationFooterComponent } from './components/pagination-footer/pagination-footer.component';

registerLocaleData(me);

@NgModule({
  declarations: [
    LoadingSpinnerCircleComponent,
    BloodSupplyAnimatedComponent,
    PaginationFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  exports: [
    LoadingSpinnerCircleComponent,
    BloodSupplyAnimatedComponent,
    PaginationFooterComponent,
    CalendarModule
  ]
})
export class SharedModule { }
