import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './shared/services/guards/auth-guard.guard';
import { CanActivateRole } from './shared/services/guards/auth-role.guard';

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
    }, {
      path: '',
      component: AdminLayoutComponent,
      canActivate: [AuthGuard],
      children: [
          {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
    },
    {
        path: '',
        loadChildren: './material-dashboard/material-dashboard.module#MaterialDashboardModule'
    },
    {
        path: '',
        canActivateChild: [CanActivateRole],
        loadChildren: './notifications-table/notifications.module#NotificationsModule'
    },
    {
        path: '',
        loadChildren: './reservations/reservations.module#ReservationsModule'
    },
    {
        path: '',
        loadChildren: './donations/donations-table/donations.module#DonationsModule'
    },
    {
        path: '',
        loadChildren: './reports/reports.module#ReportsModule'
    },
    {
        path: '',
        loadChildren: './faculty/faculty.module#FacultyModule'
    },
    {
        path: '',
        canActivateChild: [CanActivateRole],
        loadChildren: './zavod-users/zavod-users.module#ZavodUsersModule'
    },
    {
        path: '',
        loadChildren: './blood-group/blood-group.module#BloodGroupModule'
    },
    {
        path: 'components',
        loadChildren: './components/components.module#ComponentsModule'
    }, {
        path: 'forms',
        loadChildren: './forms/forms.module#Forms'
    }, {
        path: 'tables',
        loadChildren: './tables/tables.module#TablesModule'
    }, {
        path: 'maps',
        loadChildren: './maps/maps.module#MapsModule'
    }, {
        path: 'widgets',
        loadChildren: './widgets/widgets.module#WidgetsModule'
    }, {
        path: 'charts',
        loadChildren: './charts/charts.module#ChartsModule'
    }, {
        path: 'calendar',
        loadChildren: './calendar/calendar.module#CalendarModule'
    }, {
        path: '',
        loadChildren: './userpage/user.module#UserModule'
    }, {
        path: '',
        loadChildren: './timeline/timeline.module#TimelineModule'
    }
  ]}, {
      path: '',
      component: AuthLayoutComponent,
      children: [{
        path: '',
        loadChildren: './public/public.module#PublicModule'
      }]
    }
];
