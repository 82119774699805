import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../storage.service';
import { IfStmt } from '@angular/compiler';

@Injectable()
export class AbInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const adminToken = this.storageService.sessionGet('auth_token');
    if (adminToken != null && adminToken !== undefined) {
      const cloned = req.clone({
          headers: req.headers.set('Authorization',
          'Bearer ' + adminToken)
      });

      return next.handle(cloned);
    } 

    if(req.method === 'GET') {
      console.log(req);
    }

    return next.handle(req);
  }

}
