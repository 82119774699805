import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { StorageService } from '../shared/services/storage.service';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
        path: '/dashboard',
        title: 'Početna',
        type: 'link',
        icontype: 'dashboard'
    },
    {
        path: '/',
        title: 'Rezervacije',
        type: 'sub',
        icontype: 'more_time',
        collapse: 'reservationsCollapse',
        children: [
            {
                path: 'reservations-calendar',
                title: 'Kalendarski pregled',
                ab: '',
                type: 'link',
            },
            {
                path: 'reservations',
                title: 'Tabelarni pregled',
                ab: '',
                type: 'link',
            },
        ]
    },
    {
        path: '/donations',
        title: 'Donacije',
        type: 'link',
        icontype: 'local_pharmacy'
    },
    {
        path: '/faculties',
        title: 'Ustanove',
        type: 'link',
        icontype: 'apartment'
    }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    loggedInUser: any;

    constructor(private storage: StorageService) {}
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.loggedInUser = this.storage.sessionGet('loggedInUser');
        
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if(this.loggedInUser.rola === 'superadmin') {
            this.menuItems.push(
                {
                    path: '/',
                    title: 'Izvještaji',
                    type: 'sub',
                    icontype: 'more_time',
                    collapse: 'reportsCollapse',
                    children: [
                        {
                            path: 'donacije-po-krvnim-grupama',
                            title: 'Donacije po krvnim grupama',
                            ab: '',
                            type: 'link',
                        },
                        {
                            path: 'odziv-na-rezervaciju',
                            title: 'Odziv na rezervaciju',
                            ab: '',
                            type: 'link',
                        },
                    ]
                },
                {
                    path: '/notifications',
                    title: 'Notifikacije',
                    type: 'link',
                    icontype: 'notifications'
                },
                {
                    path: '/zavod-users',
                    title: 'Korisnici (Zavod)',
                    type: 'link',
                    icontype: 'person'
                }
            );
        }

        
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
