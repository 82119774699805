import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth.service";

@Injectable()
export class CanActivateRole implements CanActivateChild {
  currentUser: any;
  constructor(private auth: AuthService) {}

  // tslint:disable-next-line: max-line-length
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    this.currentUser = this.auth.loggedInUser;
    return route.data.roles.indexOf(this.currentUser.rola) >= 0;

    // We might use this later....
    // if(state.url.indexOf('moj-profil') < 0){
    //   return route.data.roles.indexOf(this.currentUser.rola) >= 0;
    // } else {
    //   return true;
    // }
  }
}