import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blood-supply-animated',
  templateUrl: './blood-supply-animated.component.html',
  styleUrls: ['./blood-supply-animated.component.css']
})
export class BloodSupplyAnimatedComponent implements OnInit {
  @Input("supplyLevel") supplyLevel: number;
  @Input("groupName") groupName: string;
  bloodHeight = 0;
  bloodHeightInPixels = '0px';

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.calculateBloodHeight();
    }, 500);
  }

  calculateBloodHeight() {
    this.bloodHeight = this.supplyLevel * 2;
    this.bloodHeightInPixels = this.bloodHeight + 'px';
  }

}
