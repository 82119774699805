import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../Config';
import { HttpHeaders, HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public _loggedIn = new BehaviorSubject<boolean>(false);
  public _loginFailed = new BehaviorSubject<boolean>(false);
  private config: Config;
  baseUrl: string;
  adminUrl: string;
  header = new HttpHeaders();

  constructor(
    private router: Router,
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.config = new Config();
    this.baseUrl = this.config.baseUrl;
  }

  get isLoggedIn() {
    if (this.storageService.sessionGet('isLoggedIn') === true) {
      this._loggedIn.next(true);
    }

    return this._loggedIn.asObservable();
  }

  /*
   * Authenticate partner user with login and password.
   *
   * @param authenticationRequest authenticationRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public loginRequest(loginData: {login: string, password: string}) {
    const url =  this.baseUrl + 'auth/login';
    const dataToSend = {username: loginData.login, password: loginData.password};
    return this.http.post(url, dataToSend, { headers: this.header});
  }


  get loginFailed() {
    return this._loginFailed.value;
  }

  get loggedInUser() {
    return this.storageService.sessionGet('loggedInUser');
  }

  login() {
    this._loggedIn.next(true);
  }

  logout() {
    this.storageService.sessionDelete('isLoggedIn');
    this.storageService.sessionDelete('loggedInUser');
    this.storageService.sessionDelete('auth_token');
    this._loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  hasRole(role: Role) {
    const user = this.storageService.sessionGet('loggedInUser');
    if(user != null && user !== undefined) {
      return user.rola === role;
    } else {
      return false;
    }
  }
}
