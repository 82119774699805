import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Config } from '../Config';
import { BloodGroupLevel } from '../models/blood-group-level.model';
import { Donation } from '../models/donation.model';
import { Reservation } from '../models/reservation.model';
import { UserNotification } from '../models/user-notification.model';
import { Faculty } from '../models/faculty.model';

@Injectable()
export class MockupService {

  private config: Config;
  private header: HttpHeaders;



  constructor(private http: HttpClient) {
    this.config = new Config();
    this.header = new HttpHeaders();
    this.header.append('Content-Type', 'application/json');
  }


  bloodGroupLevels: Array<BloodGroupLevel> = [
    {
      id: 1,
      group: 'A+',
      quantity: 2.25,
    },
    {
      id: 2,
      group: 'A-',
      quantity: 2.7
    },
    {
      id: 3,
      group: 'B+',
      quantity: 3.15
    },
    {
      id: 4,
      group: 'B-',
      quantity: 4.05,
    },
    {
      id: 5,
      group: 'AB+',
      quantity: 3.6
    },
    {
      id: 6,
      group: 'AB-',
      quantity: 4.5
    },
    {
      id: 7,
      group: '0+',
      quantity: 1.8
    },
    {
      id: 8,
      group: '0-',
      quantity: 1.35
    }
  ];

  // donations: Array<Donation> = [
  //   {
  //     id: 1,
  //     bloodGroup: 'A+',
  //     quantity: 0.45,
  //     reservationId: 1
  //   },
  //   {
  //     id: 2,
  //     bloodGroup: 'A-',
  //     quantity: 0.45,
  //     reservationId: 2
  //   },
  //   {
  //     id: 3,
  //     bloodGroup: 'B-',
  //     quantity: 0.45,
  //     reservationId: 3
  //   },
  //   {
  //     id: 4,
  //     bloodGroup: 'B+',
  //     quantity: 0.45,
  //     reservationId: 4
  //   },
  //   {
  //     id: 5,
  //     bloodGroup: 'A+',
  //     quantity: 0.45,
  //     reservationId: 5
  //   },
  //   {
  //     id: 10,
  //     bloodGroup: 'AB+',
  //     quantity: 0.45,
  //     reservationId: 6
  //   },
  //   {
  //     id: 15,
  //     bloodGroup: 'A+',
  //     quantity: 0.45,
  //     reservationId: 7
  //   },
  //   {
  //     id: 20,
  //     bloodGroup: 'AB+',
  //     quantity: 0.45,
  //     reservationId: 8
  //   },
  //   {
  //     id: 21,
  //     bloodGroup: 'AB-',
  //     quantity: 0.45,
  //     reservationId: 9
  //   }

  // ];

//   reservations: Array<Reservation> = [
//     {id: 2, userId: 1, facultyId: 1, datetime: new Date( '04.04.2020'), bloodGroup: 'A+'},
//     // {id: 3, userId: 2, facultyId: 2, datetime: new Date( '24.03.2020'), bloodGroup: 'A-'},
//     {id: 4, userId: 3, facultyId: 3, datetime: new Date( '11.09.2019'), bloodGroup: 'AB+'},
//     {id: 5, userId: 4, facultyId: 4, datetime: new Date( '09.28.2019'), bloodGroup: '0+'},
//     {id: 6, userId: 5, facultyId: 5, datetime: new Date( '12.21.2019'), bloodGroup: 'AB+'},
//     {id: 7, userId: 6, facultyId: 6, datetime: new Date( '04.14.2020'), bloodGroup: 'B+'},
//     {id: 8, userId: 7, facultyId: 7, datetime: new Date( '03.26.2020'), bloodGroup: 'AB-'},
//     {id: 9, userId: 8, facultyId: 8, datetime: new Date( '09.02.2020'), bloodGroup: 'AB-'},
//     {id: 10, userId: 9, facultyId: 9, datetime: new Date( '07.03.2020'), bloodGroup: 'A-'},
//     {id: 11, userId: 10, facultyId: 10, datetime: new Date( '02.26.2020'), bloodGroup: 'B-'},
//     {id: 12, userId: 11, facultyId: 11, datetime: new Date( '12.25.2019'), bloodGroup: '0-'},
//     {id: 13, userId: 12, facultyId: 12, datetime: new Date( '04.12.2019'), bloodGroup: '0-'},
//     {id: 14, userId: 13, facultyId: 13, datetime: new Date( '06.25.2019'), bloodGroup: 'B+'},
//     {id: 15, userId: 14, facultyId: 14, datetime: new Date( '05.07.2019'), bloodGroup: 'B+'},
//     {id: 16, userId: 15, facultyId: 15, datetime: new Date( '08.22.2019'), bloodGroup: 'B-'},
//     {id: 17, userId: 16, facultyId: 16, datetime: new Date( '01.08.2019'), bloodGroup: 'AB+'},
//     {id: 18, userId: 17, facultyId: 17, datetime: new Date( '11.25.2019'), bloodGroup: 'AB-'},
//     {id: 19, userId: 18, facultyId: 18, datetime: new Date( '12.26.2019'), bloodGroup: 'B+'},
//     {id: 20, userId: 19, facultyId: 19, datetime: new Date( '10.10.2019'), bloodGroup: 'A+'},
//     {id: 21, userId: 20, facultyId: 20, datetime: new Date( '07.06.2019'), bloodGroup: 'A-'},
//     {id: 22, userId: 21, facultyId: 21, datetime: new Date( '01.25.2020'), bloodGroup: 'AB-'},
//     {id: 23, userId: 22, facultyId: 22, datetime: new Date( '02.02.2020'), bloodGroup: '0+'},
//     {id: 24, userId: 23, facultyId: 23, datetime: new Date( '09.13.2019'), bloodGroup: 'B+'},
//     {id: 25, userId: 24, facultyId: 24, datetime: new Date( '09.29.2019'), bloodGroup: 'B-'},
//     {id: 26, userId: 25, facultyId: 25, datetime: new Date( '05.30.2019'), bloodGroup: 'B+'},
//     {id: 27, userId: 26, facultyId: 26, datetime: new Date( '07.26.2019'), bloodGroup: 'AB+'},
//     {id: 28, userId: 27, facultyId: 27, datetime: new Date( '12.13.2019'), bloodGroup: 'A+'},
//     {id: 29, userId: 28, facultyId: 28, datetime: new Date( '12.25.2019'), bloodGroup: 'A-'},
//     {id: 30, userId: 29, facultyId: 29, datetime: new Date( '10.15.2019'), bloodGroup: '0+'},
//     {id: 31, userId: 30, facultyId: 30, datetime: new Date( '08.09.2019'), bloodGroup: 'B+'}
// ];

// notifications: Array<UserNotification> = [
//   // tslint:disable-next-line: max-line-length
//   {id: 2, text:'Hitno potrebne 2 jedinice krvi za krvnu grupu AB+ u Podgorici',     facultyId: 3, bloodGroupId: 8, status:'A', sentDate: null, adminUserEdited:1, lastChangeDate: new Date('12/16/2019')},
//   // tslint:disable-next-line: max-line-length
//   {id: 3, text:'Hitno potrebne 2 jedinice krvi za krvnu grupu A+ u Nikšiću', facultyId:3,bloodGroupId:1,status:'A',sentDate: null,adminUserEdited:1,lastChangeDate: new Date('5/3/2019')},
//   // tslint:disable-next-line: max-line-length
//   {id: 4, text:'Hitno potrebne 2 jedinice krvi za krvnu grupu AB+ u Podgorici', facultyId:1,bloodGroupId:6,status:'S',sentDate: new Date('02.11.2019'),adminUserEdited:1,lastChangeDate: new Date('02.11.2019')},
//   // tslint:disable-next-line: max-line-length
//   {id: 5, text:'Hitno potrebne 2 jedinice krvi za krvnu grupu A- u Podgorici', facultyId:2,bloodGroupId:8,status:'S',sentDate: new Date('01.06.2019'),adminUserEdited:1,lastChangeDate: new Date('01.06.2019')},
//   // tslint:disable-next-line: max-line-length
//   {id: 6, text:'Hitno potrebne 3 jedinice krvi za krvnu grupu B+ u Bijelom Polju', facultyId:3,bloodGroupId:3,status:'S',sentDate: new Date('01.03.2020'),adminUserEdited:1,lastChangeDate: new Date('01.03.2020')},
//   // tslint:disable-next-line: max-line-length
//   {id: 7, text:'Hitno potrebne 2 jedinice krvi za krvnu grupu 0- u Podgorici', facultyId:3,bloodGroupId:6,status:'S',sentDate:  new Date('11.04.2020'),adminUserEdited:1,lastChangeDate: new Date('11.04.2020')},
//   // tslint:disable-next-line: max-line-length
//   {id: 8, text:'Hitno potrebne 1 jedinice krvi za krvnu grupu B+ u Nikšiću', facultyId:2,bloodGroupId:6,status:'S',sentDate: new Date('10.09.2019'), adminUserEdited:1,lastChangeDate: new Date('10.09.2019')},
//   // tslint:disable-next-line: max-line-length
//   {id: 9, text:'Hitno potrebne 3 jedinice krvi za krvnu grupu A+ u Podgorici', facultyId:2,bloodGroupId:2,status:'S',sentDate: new Date('07.01.2020'), adminUserEdited:1,lastChangeDate: new Date('07.01.2020')}
// ];

bloodGroups = [
    {
      id: 1,
      grupa: 'A+'
    },
    {
      id: 2,
      grupa: 'A-'
    },
    {
      id: 3,
      grupa: 'B+'
    },
    {
      id: 4,
      grupa: 'B-'
    },
    {
      id: 5,
      grupa: 'AB+'
    },
    {
      id: 6,
      grupa: 'AB-'
    },
    {
      id: 7,
      grupa: '0+'
    },
    {
      id: 8,
      grupa: '0-'
    }
];

 cities = [
   { }
 ]


  // getNotifications(status?: string) {
  //   if(status != null && status !== undefined) {
  //     let arr =  this.notifications.filter((elem) => {
  //       return elem.status === status;
  //     });

  //     return of(arr);
  //   } else {
  //     return of(this.notifications);
  //   }
  // }


  // getReservations() {
  //   return of(this.reservations);
  // }

  // getDonations() {
  //   return of(this.donations);
  // }


  getBloodGroupLevels() {
    return of(this.bloodGroupLevels);
  }

  getBloodGroups() {
    return of(this.bloodGroups);
  }

  getFaculties() {
    const url = this.config.baseUrl + 'ustanove';

    return this.http.get<any[]>(url, {headers: this.header}).pipe(map(
        (results) => results));
  }

  getCities() {
    const url = this.config.baseUrl + 'gradovi';

    return this.http.get<any[]>(url, {headers: this.header}).pipe(map(
        (results) => results));
  }

  getZavodKorisnik() {
    const url = this.config.baseUrl + 'zavod/1';

    return this.http.get<any[]>(url, {headers: this.header}).pipe(map(
        (results) => results));
  }




}