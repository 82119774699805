import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()

export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService,
                private router: Router) { }

    superAdminRoutes = [''];

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
          const currentUser = this.auth.loggedInUser;
          console.log(route.url);
          if (currentUser) {
            return true;
          } else {
              // User not logged in
              console.log('User not logged in');
              this.router.navigate(['/login']);
              // this.navService.goToPathInCurrentOutlet(this.routerExtensions, 'default');

              return false;
          }
        }
}
