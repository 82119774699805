import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class ZavodUsersResolverService {

  constructor(private dataService: DataService,
              private storage: StorageService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if(route.params['id'] != null && route.params['id'] !== undefined){
      return this.dataService.getDataFromUrl('korisnici/' + route.params['id']);
    } else {
      const loggedInUser = this.storage.sessionGet('loggedInUser');
      return this.dataService.getDataFromUrl('korisnici/' + loggedInUser.id);
    }
  }

}
