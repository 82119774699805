import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'pagination-footer',
  templateUrl: './pagination-footer.component.html',
  styleUrls: ['./pagination-footer.component.css']
})
export class PaginationFooterComponent implements OnInit {
  @Input() totalPageNumber: number;
  @Input() currentPage = 1;
  @Input() errorExists = false;
  @Output()  pageChange = new EventEmitter<number>();
  // tslint:disable-next-line:no-inferrable-types
  maximumReached: boolean = false;
  maxPage: number = 100;
  errorText: string;
  @Input() wasClicked = false

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.maxPageReached.subscribe(
      (maxReached) => {
          if (maxReached && this.wasClicked) {
            this.maximumReached = true;
            this.maxPage = this.currentPage - 1;
            this.totalPageNumber = this.maxPage;
            this.errorText = 'Nema podataka za stranicu br.' + this.currentPage;
            this.errorExists = true;
            this.currentPage = this.totalPageNumber;
          }
      }
    );
  }

  pageSelect(page: number) {
    // this.errorExists = false;
    this.wasClicked = true;
    if (page >= this.maxPage && !this.maximumReached) {

    } else {
      if (page > this.totalPageNumber) {
        this.totalPageNumber = page;
      }
      this.currentPage = page;
      this.pageChange.emit(page);
    }
  }

  pageIs(page: number) {
    return this.currentPage === page;
  }



}
